<template>
<div>
<v-dialog
    v-model="activo"
    width="600"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">{{ nuevo? 'Nuevo Banco' : 'Detalle del Banco' }}</div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        <v-form @submit.prevent="guardarEmit()">
          <v-row class="pt-2">
            <v-col cols="12" sm="6" md="6" class="pt-1">
              Banco
              <v-text-field
                v-model="bancoCopia.banco_nombre"
                tabindex="1"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-1">
              <v-switch
                v-model="bancoCopia.inhabilitado"
                label="Inhabilitado"
                class="mb-md-2"
                tabindex="1"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="activo = false"
        >cancelar</v-btn>
        <BtnConfirmarVue
          :texto="(nuevo? 'Grabar nuevo Banco País. ' : `Aplicar cambios al Banco País: ${banco.banco_nombre}. `) + '¿Confirma esta accion?'"
          :nombre="'GUARDAR CAMBIOS'"
          :loading="load"
          @action="guardarEmit()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import BtnConfirmarVue from '../util/BtnConfirmar.vue';

export default {
  name: 'ModalNvoEditarBancoPais',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    banco:{
      get(){
        return this.datos.banco;
      },
    },
    nuevo:{
      get(){
        return this.datos.nuevo;
      },
    },
  },
  data(){
    return{
      bancoCopia: {},
      load: false,
    }
  },
  created(){
  },
  methods:{
    async init(){

    },
    guardarEmit(){
      if(!this.bancoCopia.banco_nombre){
        return this.$store.dispatch('show_snackbar', { text: 'Debe ingresar un Banco.', color: 'warning' })
      }
      this.$emit('guardarCambios', this.bancoCopia, this.nuevo);
    },
  },
  components:{
    BtnConfirmarVue
  },
  watch:{
    activo: function(val){
      if(val){
        this.bancoCopia = JSON.parse(JSON.stringify(this.banco));
      }
    },
  }
}
</script>

<style>

</style>