<template>
  <v-dialog
    v-model="dialog"
    width="850"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        <div>
          <div class="mb-n2">
            {{ nuevo ? 'Nuevo' : (editar ? 'Editar' : 'Detalle') }} costo financiero
            {{ nuevo ? 'para' : 'de' }} {{ p_cuota.tarjeta_nombre }}
          </div>
          <span class="body-1">
            {{  p_cuota.nombre  }} - {{ p_cuota.empresa_nombre }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-switch
          v-if="!nuevo"
          v-model="editar"
          label="Editar"
          class="d-flex align-top mt-0"
          hide-details
          dense
        ></v-switch>
        <v-divider
          v-if="$vuetify.breakpoint.smAndUp"
          class="mx-3"
          vertical
        ></v-divider>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="costo.tipo"
                label="Tipo descuento"
                item-text="nombre"
                item-value="id"
                :items="tp_descuento_cuota.filter(tp => !p_costos.find(cos => cos.tipo == tp.id) || tp.id == costo.tipo)"
                :rules="nuevo ? [rules.required] : []"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-autocomplete
                v-model="costo.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales_all"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <!-- <v-text-field-percent
                v-model="costo.alicuota"
                label="Alicuota"
                v-bind:properties="{
                  suffix: '%',
                  rules: editar ? [rules.required] : [],
                  readonly: !editar,
                  filled: !editar,
                  'validate-on-blur': true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  length: 4,
                  precision: 2,
                  empty: null
                }"
                @blur="!nuevo ? limpiar_fechas() : ''"
              /> -->
              <v-text-field-masked
                v-model="costo.alicuota"
                label="Alicuota"
                type="percentage"
                :empty="null"
                :length="3"
                :precision="2"
                v-bind:properties="{
                  outlined: true,
                  dense: true,
                  rules: editar ? [rules.required] : [],
                  readonly: !editar,
                  filled: !editar,
                  'validate-on-blur': true
                }"
                @blur="!nuevo ? limpiar_fechas() : ''"
              ></v-text-field-masked>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <FechaPicker
                v-model="costo.desde"
                label="Vig. desde"
                :disabled="!nuevo && (!editar || (p_costo.alicuota == costo.alicuota))"
                :hideDetails="true"
              />
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <FechaPicker
                v-model="costo.hasta"
                label="Vig. hasta"
                :key="keyHasta"
                :fecha_minima="costo.desde ? moment(parseDate(costo.desde)).format('DD/MM/YYYY') : null"
                :disabled="!editar || costo.desde == null"
                :clearable="nuevo || (editar && p_costo.hasta == null)"
                :hideDetails="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4 pt-6 pt-sm-2">
        <v-btn
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          clase="ml-3"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { parseDate, roundNumber } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      parseDate: parseDate,
      keyHasta: 1,
      load: false,
      nuevo: false,
      editar: false,
      sucursales_all: [],
      costo: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('cuotas/get_tipos_decuento')
    await this.$store.dispatch('genericosAcc/get_sucursales')
    this.$store.state.loading = false
    this.sucursales_all = JSON.parse(JSON.stringify(this.sucursales))
    this.sucursales_all.push({
      id: null,
      nombre: 'TODAS'
    })
  },
  props: {
    value: Boolean,
    p_costos: Array,
    p_cuota: Object,
    p_costo: Object
  },
  computed: {
    ...mapState('cuotas', ['tp_descuento_cuota']),
    ...mapState('genericosAcc', ['sucursales']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_costo == null) {
          this.nuevo = true
          this.editar = true
          this.costo = {
            tipo: null,
            sucursal: null,
            alicuota: null,
            desde: null,
            hasta: null
          }
        }
        else {
          this.nuevo = false
          this.editar = false
          this.costo = JSON.parse(JSON.stringify(this.p_costo))
        }
      }
      else {
        this.$refs.form.resetValidation()
        this.costo = {}
      }
    },
    async editar (val) {
      if (!val) {
        const costo_hasta = JSON.parse(JSON.stringify(this.costo.hasta))
        this.costo = JSON.parse(JSON.stringify(this.p_costo))
        // si el hasta original era null y se ingreso un hasta se debe refrescar el componente pq el v-mask bugea el input y no actualiza el valor
        if (this.costo.hasta == null && costo_hasta != null) {
          this.keyHasta ++
        }
      }
    }
  },
  methods: {
    limpiar_fechas () {
      // si es distinto limpio las fechas hasta
      if (this.costo.alicuota != this.p_costo.alicuota) {
        this.costo.desde = null
        this.costo.hasta = null
      }
      // si volvio a ponerlos igual vuelvo a las fechas que tenia
      else {
        this.costo.desde = JSON.parse(JSON.stringify(this.p_costo.desde))
        this.costo.hasta = JSON.parse(JSON.stringify(this.p_costo.hasta))
      }
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        // validaciones
        /*if (this.costo.alicuota <= 0) {
          return this.$store.dispatch('show_snackbar', {
            text: 'La alicuota debe ser mayor a 0',
            color: 'orange'
          })
        }*/
        if (!this.costo.desde) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Seleccione una vigencia desde',
            color: 'orange'
          })
        }
        this.load = true
        // NUEVO
        if (this.nuevo) {
          await this.$store.dispatch('cuotas/nuevo_costo_financiero', {
            cuota_id: this.p_cuota.codigo,
            tipo: this.costo.tipo,
            sucursal: this.costo.sucursal,
            alicuota: this.costo.alicuota,
            desde: this.costo.desde,
            hasta: this.costo.hasta
          })
            .then((res) => {
              this.dialog = false
              // actualizo el componente padre
              this.$emit('actualizar')
              // muestro mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        // EDITAR
        else {
          // solo edito si realmente cambio algun valor
          if (JSON.stringify(this.costo) != JSON.stringify(this.p_costo)) {
            await this.$store.dispatch('cuotas/editar_costo_financiero', this.costo)
              .then((res) => {
                this.dialog = false
                // actualizo el componente padre
                this.$emit('actualizar')
                // muestro mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
          else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el costo financiero: los campos nuevos son iguales a los anteriores',
              color: 'orange',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>

<style scoped>
  
</style>